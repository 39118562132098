import { Box, Input } from '@chakra-ui/react'
import { FC } from 'react'
import { IoIosCloseCircle } from 'react-icons/io'
import dictionary from '../../dictionary'

type Props = {
  register: any
  inputId: string
  previewUrl: string | null
  deleteFile: () => void
}
const UploadFileInput: FC<Props> = ({ register, inputId, previewUrl, deleteFile }) => {
  return (
    <>
      {previewUrl ? (
        <Box position='relative'>
          <img
            src={previewUrl}
            style={{
              width: '100%',
              height: '80px',
              borderRadius: '10px',
              border: '1px dashed rgba(133, 148, 177, 0.50)',
              marginBottom: '10px',
            }}
          />
          {deleteFile && (
            <span
              onClick={() => deleteFile()}
              data-test={`${inputId}_delete`}
              style={{ position: 'absolute', top: '-10px', left: '-7px', cursor: 'pointer', color: '#F70000', fontSize: '20px' }}
            >
              <IoIosCloseCircle />
            </span>
          )}
        </Box>
      ) : (
        <>
          <label
            htmlFor={inputId}
            style={{
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              gap: '10px',
              justifyContent: 'center',
              padding: '0px 5px',
              borderRadius: '10px',
              border: '1px dashed rgba(133, 148, 177, 0.50)',
              width: '100%',
              height: '80px',
            }}
          >
            <img src='/assets/UploadPic.svg' alt='upload' />
            <b>{dictionary().UPLOAD_FILES}</b>
          </label>
          <Input data-test={inputId} id={inputId} type='file' {...register} accept='image/*' style={{ display: 'none' }} />
        </>
      )}
    </>
  )
}

export default UploadFileInput
