import { FC } from 'react'
import { useMutation } from 'react-query'
import fetch from '../../helpers/fetch'
import useAlert from '../../helpers/useAlert'

type Props = {
  adsData: any
  updateData?: () => void
}

const AdsSorting: FC<Props> = ({ adsData, updateData }) => {
  const { onSuccess } = useAlert()
  const currentSort = adsData?.sort_order
  const { mutate, isLoading } = useMutation((sort_order: string) => fetch('PATCH', `/banner/${adsData?.id}`, { data: { sort_order } }), {
    onSuccess: () => {
      onSuccess()
      updateData && updateData()
    },
  })

  const sortUp = () => {
    currentSort !== 1 && mutate(`${currentSort - 1}`)
  }
  const sortDown = () => {
    mutate(`${currentSort + 1}`)
  }
  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '10px' }}>
        <img
          data-test='arrow-up'
          src='/assets/Arrow.svg'
          style={{ cursor: currentSort !== 1 ? 'pointer' : 'not-allowed' }}
          onClick={isLoading ? () => {} : sortUp}
        />
        <img
          data-test='arrow-down'
          src='/assets/Arrow.svg'
          style={{ cursor: 'pointer', transform: 'rotate(180deg)' }}
          onClick={isLoading ? () => {} : sortDown}
        />
      </div>
      <b>{adsData?.sort_order}</b>
    </div>
  )
}

export default AdsSorting
