import { Box, Table, Tbody, Td, Tr } from '@chakra-ui/react'
import { FC } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { useMutation } from 'react-query'
import { TableHead } from '../../components/core/Table/TableHead'
import fetch from '../../helpers/fetch'
import useAlert from '../../helpers/useAlert'
import AdsImages from './ads-images'
import AdsSorting from './ads-sorting'
import DeleteAds from './delete-ads'
import EditAds from './edit'

type Props = {
  updateData: () => void
  headers: any
  adsData: any
  setAdsData: any
}

const AdsTable: FC<Props> = ({ updateData, headers, adsData, setAdsData }) => {
  const { onSuccess } = useAlert()

  const getCellData = (header: any, item: any) => {
    switch (header.id) {
      case 'adsId':
        return <AdsSorting adsData={item} updateData={updateData} />
      case 'adsImage':
        return <AdsImages images={item.advertisement} />
      case 'adsActions':
        return (
          <Box display='flex' gap={4}>
            <EditAds data={item} updateData={updateData} />
            <DeleteAds id={item?.id} updateData={updateData} />
          </Box>
        )
      default:
        return <></>
    }
  }

  const { mutate: updateSort } = useMutation(({ id, sort_order }: any) => fetch('PATCH', `/banner/${id}`, { data: { sort_order } }), {
    onSuccess: () => {
      onSuccess()
      updateData?.()
    },
  })

  const onDragEnd = (result: any) => {
    const { source, destination } = result

    // Check if dropped outside the droppable area
    if (!destination) return

    // Reorder the data array
    const newData = Array.from(adsData)
    const [movedItem] = newData.splice(source.index, 1)
    newData.splice(destination.index, 0, movedItem)

    updateSort({ id: adsData[source.index].id, sort_order: `${adsData[destination.index]?.sort_order}` })
    setAdsData(newData)
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Table variant='striped' data-test='table'>
        <TableHead headers={headers} />
        <Droppable droppableId='droppable-table'>
          {(provided: any) => (
            <Tbody ref={provided.innerRef} {...provided.droppableProps}>
              {adsData.map((item: any, index: number) => (
                <Draggable key={item.id} draggableId={`${item.id}`} index={index}>
                  {(provided: any) => (
                    <Tr ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                      {headers.map((header: any) => (
                        <Td key={header.id}>{getCellData(header, item)}</Td>
                      ))}
                    </Tr>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </Tbody>
          )}
        </Droppable>
      </Table>
    </DragDropContext>
  )
}

export default AdsTable
