import React, { useState } from 'react'
import { AiFillCar, AiFillDollarCircle, AiOutlineCar, AiOutlineTransaction } from 'react-icons/ai'
import { BiTrip } from 'react-icons/bi'
import { BsFillPeopleFill } from 'react-icons/bs'
import { FaRegNewspaper } from 'react-icons/fa'
import { FiHome } from 'react-icons/fi'
import { HiDocumentDuplicate, HiReceiptTax } from 'react-icons/hi'
import { IoMdPhonePortrait } from 'react-icons/io'
import { IoAnalytics, IoReceipt } from 'react-icons/io5'
import { MdOutlinePayments, MdOutlineSwapVerticalCircle, MdProductionQuantityLimits } from 'react-icons/md'
import { RiCoinsFill, RiRefund2Fill } from 'react-icons/ri'
import { TbUsers } from 'react-icons/tb'
import { FEATURE_FLAGS } from '../../amplitude/FEATURE_FLAGS'
import { useFeatureFlags } from '../../amplitude/useFeatureFlags'
import dictionary from '../../dictionary'
import { useStore } from '../../store'
import NavItem from '../core/NavItem'

type Props = {
  type: string
}

const AdminSideBar: React.FC<Props> = ({ type }) => {
  const { role } = useStore()
  const { isFeatureFlagEnabled } = useFeatureFlags()
  const isTaxIdFlagEnabled = isFeatureFlagEnabled(FEATURE_FLAGS.DEV_TAX_ID_MANAGEMENT)
  console.log('🚀 ~ isTaxIdFlagEnabled:', isTaxIdFlagEnabled)

  const userData = window.localStorage.getItem('activeRole')

  const userType = userData && JSON.parse(userData)?.userType
  const isSuperAdmin = userType === 'admin' && role === 'SUPER'
  const showPos = userType === 'admin' && (role === 'SUPER' || role === 'MANAGER')

  const userDefinion = {
    icon: IoReceipt,
    title: dictionary().USER_DEFINTION,
    subItems: [
      {
        icon: TbUsers,
        title: dictionary().CUSTOMERS,
        to: '/customers',
      },
      {
        icon: BsFillPeopleFill,
        title: dictionary().CORPORATES,
        to: '/corporates',
      },
      {
        icon: MdOutlineSwapVerticalCircle,
        title: dictionary().CHARGING_PROFILES,
        to: '/charging-profiles',
      },
      {
        icon: BsFillPeopleFill,
        title: dictionary().CORPORATE_GROUPS,
        to: '/corporate-groups',
      },
      {
        title: dictionary().VEHICLES,
        to: '/vehicles',
        icon: AiFillCar,
      },
      {
        title: dictionary().DRIVERS,
        to: '/drivers',
        icon: BsFillPeopleFill,
      },
      {
        title: dictionary().STATIONS,
        to: '/stations',
        icon: BsFillPeopleFill,
      },
      {
        title: dictionary().STATION_PROVIDERS,
        to: '/station-providers',
        icon: BsFillPeopleFill,
      },
      {
        title: dictionary().TRIPS,
        to: '/trips',
        icon: BiTrip,
      },
    ],
  }

  isSuperAdmin &&
    userDefinion.subItems.splice(0, 0, {
      title: dictionary().ADMINS,
      to: '/admins',
      icon: BsFillPeopleFill,
    })
  isTaxIdFlagEnabled &&
    isSuperAdmin &&
    userDefinion.subItems.splice(4, 0, {
      icon: HiReceiptTax,
      title: dictionary().TAX_IDS,
      to: '/tax-ids',
    })
  showPos &&
    userDefinion.subItems.splice(1, 0, {
      title: dictionary().POS,
      to: '/pos-devices',
      icon: IoMdPhonePortrait,
    })

  const operationTabs = {
    icon: AiFillDollarCircle,
    title: dictionary().OPERATIONS,
    subItems: [
      {
        title: dictionary().CORPORATES_DEPOSIT,
        to: '/corporates-deposits',
        icon: BsFillPeopleFill,
      },
      {
        title: dictionary().STATION_PROVIDERS_DEPOSIT,
        to: '/station-providers-deposits',
        icon: BsFillPeopleFill,
      },
      {
        title: dictionary().REFUND,
        to: '/refund-tranactions',
        icon: RiRefund2Fill,
      },
      {
        title: dictionary().PAYMENTS,
        to: '/confirmed-payments',
        icon: AiFillDollarCircle,
      },
    ],
  }

  if (role === 'SUPER' || role === 'OPERATIONS' || role === 'ACCOUNTANT' || role === 'MANAGER') {
    operationTabs?.subItems?.push({
      title: dictionary().DEPOSIT_REQUESTES,
      to: '/payments-details',
      icon: MdOutlinePayments,
    })
  }

  const accountingTabs = {
    icon: IoAnalytics,
    title: dictionary().ACCOUNTING,
    subItems: [
      {
        title: dictionary().STATIONS_ACCOUNTS,
        to: '/stations-accounts',
        icon: IoReceipt,
      },
      {
        title: dictionary().CORPORATES_ACCOUNTS,
        to: '/corporates-accounts',
        icon: IoReceipt,
      },
      {
        title: dictionary().TRANSACTIONS,
        to: '/accounting-transactions',
        icon: IoReceipt,
      },
    ],
  }

  isSuperAdmin &&
    accountingTabs.subItems.splice(0, 0, {
      title: dictionary().E_INVOICES,
      to: '/e-invoices',
      icon: IoReceipt,
    })

  const transactionsTabs = {
    icon: AiOutlineTransaction,
    title: dictionary().TRANSACTIONS,
    subItems: [
      {
        title: dictionary().CAR_SERVICES_TRANSACTION,
        to: '/car-services',
        icon: IoReceipt,
      },
    ],
  }

  const showItems =
    role === 'FIN_OPS'
      ? [
          {
            title: dictionary().FUEL_TRANSACTIONS,
            to: '/fuel-transactions',
            icon: IoReceipt,
          },
          {
            icon: AiFillDollarCircle,
            title: dictionary().OPERATIONS,
            subItems: [
              {
                title: dictionary().CORPORATES_DEPOSIT,
                to: '/corporates-deposits',
                icon: BsFillPeopleFill,
              },
              {
                title: dictionary().STATION_PROVIDERS_DEPOSIT,
                to: '/station-providers-deposits',
                icon: BsFillPeopleFill,
              },
            ],
          },
          {
            icon: IoAnalytics,
            title: dictionary().ACCOUNTING,
            subItems: [
              {
                title: dictionary().E_INVOICES,
                to: '/e-invoices',
                icon: IoReceipt,
              },
              {
                title: dictionary().STATIONS_ACCOUNTS,
                to: '/stations-accounts',
                icon: IoReceipt,
              },
              {
                title: dictionary().CORPORATES_ACCOUNTS,
                to: '/corporates-accounts',
                icon: IoReceipt,
              },
              {
                title: dictionary().TRANSACTIONS,
                to: '/accounting-transactions',
                icon: IoReceipt,
              },
            ],
          },
        ]
      : role === 'ATTENDANT'
      ? [
          {
            title: dictionary().STATIONS,
            to: '/stations',
            icon: BsFillPeopleFill,
          },
        ]
      : [
          {
            icon: FiHome,
            title: dictionary().DASHBOARD,
            to: '/',
          },
          { ...userDefinion },
          {
            icon: FaRegNewspaper,
            title: dictionary().ADS,
            to: '/ads',
          },
          {
            icon: IoReceipt,
            title: dictionary().GENERAL_DEFINTIONS,
            subItems: [
              {
                title: dictionary().VEHICLE_SUBSCRIPTIONS,
                to: '/vehicle-subscription',
                icon: AiOutlineCar,
              },
              {
                title: dictionary().VEHICLE_TYPES,
                to: '/vehicle-types',
                icon: AiOutlineCar,
              },
              {
                title: dictionary().FUEL_TYPES,
                to: '/fuel-types',
                icon: AiOutlineCar,
              },
              {
                title: dictionary().PRODUCT_TYPES,
                to: '/product-types',
                icon: MdProductionQuantityLimits,
              },
              {
                title: dictionary().PRICING,
                to: '/pricing',
                icon: RiCoinsFill,
              },
              {
                title: dictionary().STORE_PRODUCTS,
                to: '/store-products',
                icon: MdProductionQuantityLimits,
              },
            ],
          },
          { ...operationTabs },
          {
            icon: IoAnalytics,
            title: dictionary().DATA,
            subItems: [
              {
                title: dictionary().TRANSACTIONS_TO_BE_REVIEWED,
                to: '/transactions-reviewed',
                icon: IoReceipt,
              },
              {
                title: dictionary().DUPLICATED_TRANSACTIONS,
                to: '/duplicate-transactions',
                icon: HiDocumentDuplicate,
              },
              {
                title: dictionary().TOLL_TRANSACTIONS,
                to: '/toll-transactions',
                icon: IoReceipt,
              },
              {
                title: dictionary().FUEL_TRANSACTIONS,
                to: '/fuel-transactions',
                icon: IoReceipt,
              },
              {
                title: dictionary().TRANSACTIONS_DATA_ENTRY,
                to: '/fuel-transactions-data-entry',
                icon: IoReceipt,
              },
              {
                title: dictionary().STORE_TRANSACTIONS,
                to: '/store-transactions',
                icon: IoReceipt,
              },
            ],
          },
          { ...transactionsTabs },
          { ...accountingTabs },
        ]

  const [selected, setSelected] = useState('')

  return (
    <>
      {showItems.map((item: any) => (
        <NavItem
          type={type}
          selected={selected}
          setSelected={setSelected}
          key={item.title}
          icon={item.icon}
          subItems={item.subItems}
          to={item.to}
          title={item.title}
        />
      ))}
    </>
  )
}

export default AdminSideBar
