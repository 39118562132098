import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import { QueryClientProvider, QueryClient } from 'react-query'
import { ChakraProvider } from '@chakra-ui/react'
import { CustomProvider } from 'rsuite'

import '@fontsource/ubuntu'
import '@fontsource/ubuntu/300.css'
import './index.css'
import '../src/assets/globals.css'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'
import { locale } from './locale'

import Routes from './Routes'
import { ConfirmProvider } from './helpers/useConfirm'
import { theme } from './theme'

const queryClient = new QueryClient()

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ChakraProvider theme={theme}>
        <CustomProvider locale={locale as any} rtl>
          <ConfirmProvider>
            <DndProvider backend={HTML5Backend}>
              <Router>
                <Routes />
              </Router>
            </DndProvider>
          </ConfirmProvider>
        </CustomProvider>
      </ChakraProvider>
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById('root'),
)

//
